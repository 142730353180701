import styled from 'styled-components';
import { useEffect } from 'react';
const SplashContainer = styled.div`
  margin-top: -2rem;
  color: white;
`;

const Keyword = styled.span`
  font-weight: bold;
  color: var(--your-custom-color);
  position: relative;
  margin-right: 0.2rem;

  ::before {
    content: '\u25A0';
    font-size: 0.8rem;
    position: absolute;
    top: 0;
    left: -0.5rem;
    color: var(--your-custom-color);
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  // color: var(--global-text);
  color: white;
`;

const MainContent = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  padding: 1rem;
  color: var(--global-text);
  font-size: 1rem;
  line-height: 1.6;
`;

const sections = [
  {
    title: 'Privacy Policy',
    title2: 'Privacy Policy',
    content: (
      <>
        <Paragraph>
          <Keyword>Last updated: </Keyword>
          <span>2023-09-09</span>
        </Paragraph>
        <Paragraph>
          <Keyword>Effective Date: </Keyword>
          <span>2023-09-09</span>
        </Paragraph>
        <Paragraph>
          <Keyword>Introduction: </Keyword>
          <span>
            Welcome to Fast Website. This Privacy Policy explains how we collect, use,
            and protect your personal information when you use our website and
            services.
          </span>
        </Paragraph>
      </>
    ),
  },
  {
    title2: 'Why?',
    content: (
      <>
        <Paragraph>
          Privacy Policy This privacy policy has been compiled to better serve
          those who are concerned with how their ‘Personally Identifiable
          Information’ (PII) is being used online. PII, as described in US
          privacy law and information security, is information that can be used
          on its own or with other information to identify, contact, or locate a
          single person, or to identify an individual in context. Please read
          our privacy policy carefully to get a clear understanding of how we
          collect, use, protect or otherwise handle your Personally Identifiable
          Information in accordance with our website. What personal information
          do we collect from the people that visit our blog, website or app? We
          do not collect information from visitors of our site. or other details
          to help you with your experience. Read-HxH do we collect information?
          We collect information from you Read-HxH you or enter information on
          our site. Provide us with feedback on our products or services How do
          we use your information? We may use the information we collect from
          you Read-HxH you register, make a purchase, sign up for our
          newsletter, respond to a survey or marketing communication, surf the
          website, or use certain other site features in the following ways: •
          To allow us to better service you in responding to your customer
          service requests. How do we protect your information? We do not use
          vulnerability scanning and/or scanning to PCI standards. We only
          provide articles and information. We never ask for credit card
          numbers. We use regular Malware Scanning. We do not use an SSL
          certificate • We only provide articles and information. We never ask
          for personal or private information like names, email addresses, or
          credit card numbers. Do we use ‘cookies’? Yes. Cookies are small files
          that a site or its service provider transfers to your computer’s hard
          drive through your Web browser (if you allow) that enables the site’s
          or service provider’s systems to recognize your browser and capture
          and remember certain information. For instance, we use cookies to help
          us remember and process the items in your shopping cart. They are also
          used to help us understand your preferences based on previous or
          current site activity, which enables us to provide you with improved
          services. We also use cookies to help us compile aggregate data about
          site traffic and site interaction so that we can offer better site
          experiences and tools in the future. We use cookies to: • Help
          remember and process the items in the shopping cart. • Understand and
          save user’s preferences for future visits. • Keep track of
          advertisements. • Compile aggregate data about site traffic and site
          interactions in order to offer better site experiences and tools in
          the future. We may also use trusted third-party services that track
          this information on our behalf. You can choose to have your computer
          warn you each time a cookie is being sent, or you can choose to turn
          off all cookies. You do this through your browser settings. Since
          browser is a little different, look at your browser’s Help Menu to
          learn the correct way to modify your cookies. If you turn cookies off,
          Some of the features that make your site experience more efficient may
          not function properly.It won’t affect the user’s experience that make
          your site experience more efficient and may not function properly.
          Third-party disclosure We do not sell, trade, or otherwise transfer to
          outside parties your Personally Identifiable Information. Third-party
          links We do not include or offer third-party products or services on
          our website. Google Google’s advertising requirements can be summed up
          by Google’s Advertising Principles. They are put in place to provide a
          positive experience for users.
          https://support.google.com/adwordspolicy/answer/1316548?hl=en We use
          Google AdSense Advertising on our website. Google, as a third-party
          vendor, uses cookies to serve ads on our site. Google’s use of the
          DART cookie enables it to serve ads to our users based on previous
          visits to our site and other sites on the Internet. Users may opt-out
          of the use of the DART cookie by visiting the Google Ad and Content
          Network privacy policy. We have implemented the following: •
          Remarketing with Google AdSense • Google Display Network Impression
          Reporting We, along with third-party vendors such as Google use
          first-party cookies (such as the Google Analytics cookies) and
          third-party cookies (such as the DoubleClick cookie) or other
          third-party identifiers together to compile data regarding user
          interactions with ad impressions and other ad service functions as
          they relate to our website. Opting out: Users can set preferences for
          how Google advertises to you using the Google Ad Settings page.
          Alternatively, you can opt out by visiting the Network Advertising
          Initiative Opt Out page or by using the Google Analytics Opt Out
          Browser add on. California Online Privacy Protection Act CalOPPA is
          the first state law in the nation to require commercial websites and
          online services to post a privacy policy. The law’s reach stretches
          well beyond California to require any person or company in the United
          States (and conceivably the world) that operates websites collecting
          Personally Identifiable Information from California consumers to post
          a conspicuous privacy policy on its website stating exactly the
          information being collected and those individuals or companies with
          whom it is being shared. – See more at:
          http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
          According to CalOPPA, we agree to the following: Users can visit our
          site anonymously. Once this privacy policy is created, we will add a
          link to it on our home page or as a minimum, on the first significant
          page after entering our website. Our Privacy Policy link includes the
          word ‘Privacy’ and can easily be found on the page specified above.
          You will be notified of any Privacy Policy changes: • On our Privacy
          Policy Page Can change your personal information: • By emailing us How
          does our site handle Do Not Track signals? We honor Do Not Track
          signals and Do Not Track, plant cookies, or use advertising Read-HxH a
          Do Not Track (DNT) browser mechanism is in place. Does our site allow
          third-party behavioral tracking? It’s also important to note that we
          allow third-party behavioral tracking COPPA (Children Online Privacy
          Protection Act) Read-HxH it comes to the collection of personal
          information from children under the age of 13 years old, the
          Children’s Online Privacy Protection Act (COPPA) puts parents in
          control. The Federal Trade Commission, United States’ consumer
          protection agency, enforces the COPPA Rule, which spells out what
          operators of websites and online services must do to protect
          children’s privacy and safety online. We do not specifically market to
          children under the age of 13 years old. Do we let third-parties,
          including ad networks or plug-ins collect PII from children under 13?
          Fair Information Practices The Fair Information Practices Principles
          form the backbone of privacy law in the United States and the concepts
          they include have played a significant role in the development of data
          protection laws around the globe. Understanding the Fair Information
          Practice Principles and how they should be implemented is critical to
          comply with the various privacy laws that protect personal
          information. In order to be in line with Fair Information Practices we
          will take the following responsive action, should a data breach occur:
          We will notify you via email • Within 7 business days We will notify
          the users via in-site notification • Within 7 business days We also
          agree to the Individual Redress Principle which requires that
          individuals have the right to legally pursue enforceable rights
          against data collectors and processors who fail to adhere to the law.
          This principle requires not only that individuals have enforceable
          rights against data users, but also that individuals have recourse to
          courts or government agencies to investigate and/or prosecute
          non-compliance by data processors. CAN SPAM Act The CAN-SPAM Act is a
          law that sets the rules for commercial email, establishes requirements
          for commercial messages, gives recipients the right to have emails
          stopped from being sent to them, and spells out tough penalties for
          violations. We collect your email address in order to: • Send
          information, respond to inquiries, and/or other requests or questions
          • Market to our mailing list or continue to send emails to our clients
          after the original transaction has occurred. To be in accordance with
          CANSPAM, we agree to the following: • Not use false or misleading
          subjects or email addresses. • Identify the message as an
          advertisement in some reasonable way. • Include the physical address
          of our business or site headquarters. • Monitor third-party email
          marketing services for compliance, if one is used. • Honor
          opt-out/unsubscribe requests quickly. • Allow users to unsubscribe by
          using the link at the bottom of each email. If at any time you would
          like to unsubscribe from receiving future emails, you can email us at
          support@read-hxh.com and we will promptly remove you from ALL
          correspondence. Contacting Us If there are any questions regarding
          this privacy policy, you may contact us using the information below.
          support@read-hxh.com
        </Paragraph>
      </>
    ),
  },
];

function PrivacyPolicy() {
  useEffect(() => {
    document.title = 'Privacy | Milo'; // Set the title when the component mounts
  }, []);

  return (
    <SplashContainer>
      <MainContent>
        <br />
        {sections.map((section, index) => (
          <span key={index}>
            {section.title && <h1 className='title-style'>{section.title}</h1>}
            {section.title2 && (
              <h3 className='title-style'>{section.title2}</h3>
            )}
            {section.content}
          </span>
        ))}
      </MainContent>
    </SplashContainer>
  );
}

export default PrivacyPolicy;
