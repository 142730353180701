import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import {
  // PromoNav,
  Nav,
  Footer,
  KofiFloatingChatWidget,
  Home,
  Donate,
  ErrorPage,
  // Pricing,
  Blog,
  Products,
  PrivacyPolicy,
  About,
} from './index';
import { Overlay } from './components/Overlay';
import { HelmetProvider } from 'react-helmet-async';
import HolyLoader from 'holy-loader';
import { useEffect } from 'react';
// import { Style } from '@/components/Style.tsx';
import ReactGA from 'react-ga4';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import Hotjar from '@hotjar/browser';
import loadable from '@loadable/component';
import SEO from '@/components/SEO';
import { routeMetadata } from './lib/metadata.ts';
import { AnalyticsProvider } from '@segment/analytics-react';
import { BlogPage } from '@/pages/BlogPage';

/* #__LOADABLE__ */
const LoadableWorlds = loadable(() => import('@/pages/Worlds'));

function App() {
  const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;
  ReactGA.initialize(measurementId);

  const siteId = 5063671;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  const MetadataWrapper = () => {
    const location = useLocation();
    const metadata = routeMetadata[location.pathname] || {
      title: 'Default Title',
      description: 'Default Description',
    };

    return (
      <SEO
        title={metadata.title}
        description={metadata.description}
        keywords={metadata.keywords}
        canonical={metadata.canonical}
        ogImage={metadata.ogImage}
      />
    );
  };

  function ConditionalHolyLoader() {
    const location = useLocation();

    // Show HolyLoader only for "/app.mywebsite"
    const shouldShowLoader = location.pathname === '/blog';

    return shouldShowLoader ? (
      <HolyLoader
        color='linear-gradient(to right, #00FFA7, #00FFA7)'
        height='2px'
        speed={500}
        easing='linear'
      />
    ) : null;
  }

  return (
    <>
      {/* <Overlay /> */}
      <HelmetProvider>
        {/* <PromoNav /> */}
        <Router>
          <MetadataWrapper />
          <Nav />
          <TrackPageViews />
          <ConditionalHolyLoader />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/products' element={<Products />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/:slug' element={<BlogPage />} />
            <Route path='/about' element={<About />} />
            <Route path='/donate' element={<Donate />} />
            <Route path='/pricing' element={<Blog />} />
            <Route path='/worlds' element={<LoadableWorlds />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
          <Footer />
          {/* <KofiFloatingChatWidget kofiId='milorr' /> */}
        </Router>
        <SpeedInsights />
        <Analytics />
      </HelmetProvider>
    </>
  );
}

function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    // Basic page view tracking
    ReactGA.send({ hitType: 'pageview', page: location.pathname });

    // Additional tracking based on the current page
    switch (location.pathname) {
      case '/home':
        ReactGA.send({
          hitType: 'event',
          eventCategory: 'User',
          eventAction: 'Visited Home',
        });
        break;
      case '/products':
        ReactGA.send({
          hitType: 'pageview',
          page: '/products',
          title: 'Product Catalog',
        });
        ReactGA.send({
          hitType: 'event',
          eventCategory: 'Engagement',
          eventAction: 'Viewed Products',
        });
        break;
      case '/store':
        ReactGA.send({
          hitType: 'pageview',
          page: '/store',
          title: 'Checkout Process',
        });
        ReactGA.send({
          hitType: 'timing',
          timingCategory: 'Ecommerce',
          timingVar: 'checkout load',
          timingValue: 1000,
        });
        break;
      case '/blog':
        ReactGA.send({
          hitType: 'social',
          socialNetwork: 'Twitter',
          socialAction: 'Share',
          socialTarget: '/blog',
        });
        break;
      case '/about':
        ReactGA.send({
          hitType: 'event',
          eventCategory: 'Form',
          eventAction: 'View',
          eventLabel: 'Contact Form',
        });
        break;
      // Add more cases as needed
    }

    // Example of tracking page load time
    const loadTime = window.performance.now();
    ReactGA.send({
      hitType: 'timing',
      timingCategory: 'Page Load',
      timingVar: 'load',
      timingValue: Math.round(loadTime),
    });

    // Example of tracking a custom event on every page change
    ReactGA.send({
      hitType: 'event',
      eventCategory: 'Navigation',
      eventAction: 'Page Change',
      eventLabel: location.pathname,
    });

    // Error tracking example (you might want to implement this differently based on your error handling)
    window.onerror = (message, source, lineno, colno, error) => {
      ReactGA.send({
        hitType: 'exception',
        exDescription: `${message} at ${source}:${lineno}:${colno}`,
        exFatal: false,
      });
    };
  }, [location]);

  return null;
}

export default App;
