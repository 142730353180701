import styled from 'styled-components';
import { useEffect } from 'react';

const Keyword = styled.span`
  font-weight: bold;
  color: var(--your-custom-color);
  position: relative;
  margin-right: 0.2rem;

  ::before {
    content: '\u25A0';
    font-size: 0.8rem;
    position: absolute;
    top: 0;
    left: -0.5rem;
    color: var(--your-custom-color);
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  // color: var(--global-text);
  color: white;
  background-color: darkgray;
`;

const MainContent = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  padding: 1rem;
  color: var(--global-text);
  font-size: 1rem;
  line-height: 1.6;
  background-color: #ffffff;
`;

const sections = [
  {
    title: 'Donate',
    title2: 'Zero-fee donations: Your support helps us keep the site running.',
    title3: 'Cash App',
    content: (
      <Paragraph>
        <Keyword>Link: </Keyword>
        <a href='https://cash.app/$Zyhroxrr'>https://cash.app/$Zyhroxrr</a>
      </Paragraph>
    ),
  },
  {
    title2: 'Kofi',
    content: (
      <Paragraph>
        <Keyword>Link: </Keyword>
        <a href='https://ko-fi.com/milorr'>https://ko-fi.com/milorr</a>
      </Paragraph>
    ),
  },
  {
    title2: 'Zelle',
    content: (
      <Paragraph>
        <Keyword>Link: </Keyword>
        <a href='https://cash.app/$Zyhroxrr'>https://cash.app/$Zyhroxrr</a>
      </Paragraph>
    ),
  },
  {
    title2: 'BTC',
    content: (
      <>
        <Paragraph>
          <strong>Address:</strong> 3AxPSYNfCa4KU6wYm1bsnK4Xyqz2SHc8eZ
          <img
            src='https://cdn.sanity.io/images/811eq7qv/production/7b8bc40005678e3bfa5c563387e552ce6af13908-160x160.png?fit=max&w=600&h=600'
            alt='BTC'
          />
        </Paragraph>
      </>
    ),
  },
  {
    title2: 'SOL',
    content: (
      <>
        <Paragraph>
          <strong>Address: </strong>
          <img
            src='https://cdn.sanity.io/images/811eq7qv/production/7b335149e6d654c8d5ee78e779f3b48fb3672c5d-160x160.png?fit=max&w=600&h=600'
            alt='SOL'
          />
        </Paragraph>
      </>
    ),
  },
];

function Donations() {
  useEffect(() => {
    document.title = 'Donate | Milo'; // Set the title when the component mounts
  }, []);

  return (
    <MainContent>
      <br />
      {sections.map((section, index) => (
        <span key={index}>
          {section.title && <h1 className='title-style'>{section.title}</h1>}
          {section.title2 && <h3 className='title-style'>{section.title2}</h3>}
          {section.title3 && <h3 className='title-style'>{section.title3}</h3>}
          {section.content}
        </span>
      ))}
    </MainContent>
  );
}

export default Donations;
