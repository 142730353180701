import { useState } from 'react';
import styled from 'styled-components';
import { ChevronDown, Check, HelpCircle } from 'lucide-react';

// Styled Components
const PricingContainer = styled.div`
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-radius: 8px;
  overflow: hidden;
`;

const FeatureColumn = styled.div`
  border-right: 1px solid #f0f0f0;
`;

const PlanColumn = styled.div`
  border-right: ${(props) => (props.lastColumn ? 'none' : '1px solid #f0f0f0')};
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const PlanHeader = styled.div`
  padding: 30px 20px;
  border-bottom: 1px solid #f0f0f0;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

const PlanTitle = styled.h2`
  font-size: 24px;
  margin: 0 0 10px 0;
  font-weight: 500;
  color: #333;
`;

const PlanSubtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0 0 20px 0;
  line-height: 1.4;
`;

const PlanPrice = styled.div`
  margin-top: auto;
`;

const PriceAmount = styled.span`
  font-size: 22px;
  font-weight: 600;
  color: #000;
`;

const PricePeriod = styled.span`
  font-size: 14px;
  color: #666;
`;

const PriceDiscount = styled.span`
  font-size: 14px;
  color: #666;
`;

const ActionButton = styled.button`
  background-color: #222;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  margin: 20px 0 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }
`;

const FeatureHeader = styled.div`
  padding: 30px 20px;
  min-height: 220px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const FeatureRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: ${(props) => (props.last ? 'none' : '1px solid #f0f0f0')};
`;

const FeatureCell = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  border-right: ${(props) => (props.lastColumn ? 'none' : '1px solid #f0f0f0')};
  min-height: 70px;
`;

const FeatureLabel = styled.div`
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
`;

const FeatureValue = styled.div`
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: #333;

  p {
    margin: 0;
  }

  p.secondary {
    color: #666;
    font-size: 12px;
    margin-top: 4px;
  }
`;

const RecommendedBadge = styled.span`
  background-color: #9a73ea;
  color: white;
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 20px;
  display: inline-block;
  margin-left: 10px;
`;

const CheckIcon = styled(Check)`
  margin: 0 auto;
  color: #333;
`;

const ChevronDownIcon = styled(ChevronDown)`
  margin-left: 6px;
`;

const HelpIcon = styled(HelpCircle)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: #999;
`;

const PricingPage = () => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const plans = [
    {
      title: 'Early Stage',
      subtitle: 'Newly established companies',
      price: '$27',
      period: '/month',
      discount: '(- 25%)',
      profiles: '1,000 profiles to start',
      profilesLimit: 'Limited to 3,000',
      basicSeats: '5 seats',
      basicSeatsAdd: 'Add up to 10 seats',
      operatorSeats: '2 seats',
      operatorSeatsAdd: 'Add up to 5 seats',
      teamMessenger: true,
      recommended: false,
    },
    {
      title: 'Growth',
      subtitle: 'Fast-growing companies',
      price: '$72',
      period: '/month',
      discount: '(- 25%)',
      profiles: '3,000 profiles',
      profilesLimit: 'Limited to 1 million',
      basicSeats: '5 seats',
      basicSeatsAdd: 'Add up to 1,000 seats',
      operatorSeats: '1 seats',
      operatorSeatsAdd: 'Add up to 1,000 seats',
      teamMessenger: true,
      recommended: true,
    },
    {
      title: 'Enterprise',
      subtitle: 'Companies with annual sales of over $10M',
      price: 'Contact Us',
      period: '',
      discount: '',
      profiles: 'Contact us',
      profilesLimit: '',
      basicSeats: 'Contact us',
      basicSeatsAdd: '',
      operatorSeats: 'Contact us',
      operatorSeatsAdd: '',
      teamMessenger: true,
      recommended: false,
    },
  ];

  return (
    <PricingContainer>
      <PricingGrid>
        {/* Feature labels column */}
        <FeatureColumn>
          <FeatureHeader>{/* Empty header for alignment */}</FeatureHeader>
          <FeatureCell>
            <FeatureLabel>
              Managed Users (MU) <HelpIcon />
            </FeatureLabel>
          </FeatureCell>
          <FeatureCell>
            <FeatureLabel>
              Basic seats <HelpIcon />
            </FeatureLabel>
          </FeatureCell>
          <FeatureCell>
            <FeatureLabel>
              Operator seats <HelpIcon />
            </FeatureLabel>
          </FeatureCell>
          <FeatureCell last>
            <FeatureLabel>
              Team messenger <HelpIcon />
            </FeatureLabel>
          </FeatureCell>
        </FeatureColumn>

        {/* Plan columns */}
        {plans.map((plan, index) => (
          <PlanColumn key={index} lastColumn={index === plans.length - 1}>
            <PlanHeader>
              <PlanTitle>
                {plan.title}
                {plan.recommended && (
                  <RecommendedBadge>Recommended</RecommendedBadge>
                )}
              </PlanTitle>
              <PlanSubtitle>{plan.subtitle}</PlanSubtitle>
              <PlanPrice>
                <PriceAmount>{plan.price}</PriceAmount>
                <PricePeriod>{plan.period}</PricePeriod>
                <PriceDiscount> {plan.discount}</PriceDiscount>
              </PlanPrice>
              <ActionButton>
                Learn more <ChevronDownIcon size={18} />
              </ActionButton>
            </PlanHeader>
            <FeatureCell>
              <FeatureValue>
                <p>{plan.profiles}</p>
                <p className='secondary'>{plan.profilesLimit}</p>
              </FeatureValue>
            </FeatureCell>
            <FeatureCell>
              <FeatureValue>
                <p>{plan.basicSeats}</p>
                <p className='secondary'>{plan.basicSeatsAdd}</p>
              </FeatureValue>
            </FeatureCell>
            <FeatureCell>
              <FeatureValue>
                <p>{plan.operatorSeats}</p>
                <p className='secondary'>{plan.operatorSeatsAdd}</p>
              </FeatureValue>
            </FeatureCell>
            <FeatureCell last>
              <FeatureValue>
                {plan.teamMessenger ? <CheckIcon size={22} /> : '—'}
              </FeatureValue>
            </FeatureCell>
          </PlanColumn>
        ))}
      </PricingGrid>
    </PricingContainer>
  );
};

export default PricingPage;
