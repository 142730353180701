import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: '811eq7qv',
  dataset: 'production',
  useCdn: false,
  apiVersion: '2023-01-01', // Use a stable API version
});

const builder = imageUrlBuilder(client);

export const urlFor = (source: any) => {
  return builder.image(source);
};