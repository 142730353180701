import React from 'react';
import styled from 'styled-components';
import Button2 from '@/components/Button';
import { Button } from './ui/button';
import { ArrowRight, Check } from 'lucide-react';

const CTAContainer = styled.section`
  width: 100%;
  background-color: #e1e7ff;
  padding: 20px;
  border-radius: 28px;
`;

const Content = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #000000;

  @media (max-width: 640px) {
    font-size: 1.5rem;
  }
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 1rem;
`;

const FeatureText = styled.span`
  margin-left: 0.5rem;
`;

const CTAButton = styled(Button2)`
  background-color: #232323;
  color: white;
  padding: 16px 24px;
  border-radius: 1rem;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  font-size: 1rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #000000;
  }

  svg {
    margin-left: 0.5rem;
  }

  &:hover svg {
    transform: translateX(5px);
  }
`;

export const CTA = () => {
  return (
    <CTAContainer>
      <Content>
        <Title>Start Your Customer Driven Journey</Title>

        <FeaturesContainer>
          <FeatureItem>
            <Check size={20} />
            <FeatureText>No site required</FeatureText>
          </FeatureItem>

          <FeatureItem>
            <Check size={20} />
            <FeatureText>
              Free trial of all features available for 14 days
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <Check size={20} />
            <FeatureText>Install in 3 minutes</FeatureText>
          </FeatureItem>
        </FeaturesContainer>

        <CTAButton as='a' href='#signup'>
          Talk to Sales
          <ArrowRight size={20} />
        </CTAButton>
      </Content>
    </CTAContainer>
  );
};
