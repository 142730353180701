import styled, { css } from 'styled-components';

interface ButtonProps {
  primary?: boolean;
  outlined?: boolean;
}

const Button = styled.button<ButtonProps>`
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.primary &&
    css`
      background-color: #6c5ce7;
      color: white;
      border: none;

      &:hover {
        background-color: #5649c0;
      }
    `}

  ${(props) =>
    props.outlined &&
    css`
      background-color: transparent;
      color: #6c5ce7;
      border: 1px solid #6c5ce7;

      &:hover {
        background-color: rgba(108, 92, 231, 0.1);
      }
    `}
`;

export default Button;
