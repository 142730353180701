import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #0b0b0bf6;
  color: #ffffff;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack sections vertically on smaller screens */
  }
`;

const Section = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  border-right: 1px solid #333333;

  &:last-child {
    border-right: none;
  }

  @media (max-width: 768px) {
    border-right: none; /* Remove border on mobile */
    border-bottom: 1px solid #333333; /* Add bottom border instead */

    &:last-child {
      border-bottom: none; /* Remove bottom border on the last section */
    }
  }
`;

const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Description = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 2rem;
`;

const Button = styled.button`
  background-color: #bbf7d0;
  border: none;
  color: #000000;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
`;

export const CTA2 = () => {
  return (
    <Container>
      <Section>
        <Title>Agent builder?</Title>
        <Description>
          Get the best Twitter + Multichain API, designed especially for the AI
          agents.
        </Description>
        <Button>Get Started</Button>
      </Section>
      <Section>
        <Title>Trader?</Title>
        <Description>
          We are building the most advanced X & multi-chain analytics for human
          and AI investors.
        </Description>
        <Button>Get Started</Button>
      </Section>
    </Container>
  );
};
