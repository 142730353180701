import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: #f7f7f7;
  padding: 1rem;
  font-size: 0.875rem; /* Equivalent to text-sm */
`;

const FooterContainer = styled.div`
  max-width: 7xl; /* Equivalent to max-w-7xl */
  margin: 0 auto;
`;

const FooterHeader = styled.div`
  padding-top: 3rem; /* Equivalent to py-12 */
  padding-bottom: 3rem;
  font-size: 1.5rem; /* Equivalent to text-2xl */
  font-weight: 600; /* Equivalent to font-semibold */
  color: #374151; /* Equivalent to text-gray-800 */  
`;

const FooterNav = styled.div`
  border-top: 1px solid #e5e7eb; /* Equivalent to border-gray-200 */
  padding-top: 2.5rem; /* Equivalent to py-10 */
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    /* md:flex-row */
    flex-direction: row;
  }
`;

const LogoSection = styled.div`
  margin-bottom: 2rem; /* Equivalent to mb-8 */
  @media (min-width: 768px) {
    margin-bottom: 0; /* md:mb-0 */
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Equivalent to mb-4 */
`;

const LogoImage = styled.img`
  width: 34px;
  height: 2rem; /* Equivalent to h-8 */
  margin-right: 0.5rem; /* Equivalent to mr-2 */
`;

const LanguageSelect = styled.select`
  border: 1px solid #d1d5db;
  background-color: transparent;
  color: #374151; /* Equivalent to text-gray-700 */
  padding: 0.25rem 0.5rem; /* Equivalent to px-2 py-1 */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  outline: none;
  margin-right: 3.125rem; /* Equivalent to mr-50 */
`;

const NavigationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem; /* Equivalent to gap-8 */
  width: 100%;

  @media (min-width: 768px) {
    /* md:grid-cols-5 */
    grid-template-columns: repeat(5, 1fr);
    width: auto; /* md:w-auto */
  }
`;

const ColumnTitle = styled.h6`
  margin-bottom: 1rem; /* Equivalent to mb-4 */
  font-weight: 600; /* Equivalent to font-semibold */
  color: #111827; /* Equivalent to text-gray-900 */
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Equivalent to space-y-2 */
  color: #374151; /* Equivalent to text-gray-700 */
  list-style: none;
  padding: 0;
`;

const NavItem = styled.li`
  /* Add any specific styling for list items here */
`;

const NavLink = styled.a`
  color: #374151; /* Equivalent to text-gray-700 */
  text-decoration: none;
  &:hover {
    color: #6366f1; /* Equivalent to hover:text-indigo-600 */
  }
`;

const DownloadLink = styled.a`
  display: flex;
  align-items: center;
  color: #374151; /* Equivalent to text-gray-700 */
  text-decoration: none;
  &:hover {
    color: #6366f1; /* Equivalent to hover:text-indigo-600 */
  }
`;

const NewBadge = styled.div`
  background-color: #ecfdf5; /* Equivalent to bg-green-100 */
  color: #065f46; /* Equivalent to text-green-600 */
  font-size: 0.75rem; /* Equivalent to text-xs */
  font-weight: 500; /* Equivalent to font-medium */
  padding: 0.125rem 0.5rem; /* Equivalent to px-2 py-0.5 */
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  margin-left: 0.25rem; /* Equivalent to ml-1 */
`;

const Copyright = styled.div`
  border-top: 1px solid #e5e7eb; /* Equivalent to border-gray-200 */
  margin-top: 3rem; /* Equivalent to mt-12 */
  padding-top: 2rem; /* Equivalent to pt-8 */
  font-size: 0.875rem; /* Equivalent to text-sm */
  color: #6b7280; /* Equivalent to text-gray-500 */
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <FooterHeader>Inspiration for future getaways</FooterHeader>
        <FooterNav>
          <MainSection>
            {/* Logo and Language Section */}
            <LogoSection>
              <LogoContainer>
                <LogoImage
                  src='https://external-preview.redd.it/re-zero-starting-life-in-another-world-season-3-new-visual-v0-r3GixjDtXJJMzSynq9gIKOoTdzKZ0UrA_M8fFI-hU7s.jpg?width=640&crop=smart&auto=webp&s=b5dc992e252fd6f98a8fe8c3aea49f9b9431c3e5'
                  alt='Re:Zero'
                />
              </LogoContainer>
              <LanguageSelect>
                <option>English</option>
                {/* Add other language options here */}
              </LanguageSelect>
            </LogoSection>

            {/* Main Navigation Sections */}
            <NavigationGrid>
              {/* Products Column */}
              <div>
                <ColumnTitle>Products</ColumnTitle>
                <NavList>
                  <NavItem>
                    <NavLink href='#'>Live Chat</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Meet (Call)</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Team Chat</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Workflow</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>ALF (AI Agent)</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Documents</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Forms</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Marketing</NavLink>
                  </NavItem>
                </NavList>
              </div>

              {/* Pricing Column */}
              <div>
                <ColumnTitle>Pricing</ColumnTitle>
                <div>
                  <DownloadLink href='#'>
                    Download <span className='ml-1'>›</span>
                  </DownloadLink>
                </div>
                <NavList>
                  <NavItem>
                    <NavLink href='#'>Android</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>iOS</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Windows</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>macOS</NavLink>
                  </NavItem>
                </NavList>
              </div>

              {/* Blog Column */}
              <div>
                <ColumnTitle>Blog</ColumnTitle>
                <NavList>
                  <NavItem>
                    <NavLink href='#'>Customer Cases</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Business Insights</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Quick Tips</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Compare</NavLink>
                  </NavItem>
                </NavList>
              </div>

              {/* Resources Column */}
              <div>
                <ColumnTitle>Resources</ColumnTitle>
                <NavList>
                  <NavItem>
                    <NavLink href='#'>What's New</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>User Guide</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Marketing Recipes</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Developers</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Open API</NavLink>
                  </NavItem>
                </NavList>
              </div>

              {/* Company Column */}
              <div>
                <ColumnTitle>Company</ColumnTitle>
                <NavList>
                  <NavItem>
                    <NavLink href='#'>Careers</NavLink>
                    <NewBadge>New</NewBadge>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Status</NavLink>
                    <NewBadge>Operational</NewBadge>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Open source license</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Terms</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href='#'>Privacy Policy</NavLink>
                  </NavItem>
                </NavList>
              </div>
            </NavigationGrid>
          </MainSection>

          <Copyright>© 2025 Live.</Copyright>
        </FooterNav>
      </FooterContainer>
    </StyledFooter>
  );
};
