import styled from 'styled-components';
import Button2 from '@/components/Button';
import { ArrowRight } from 'lucide-react';
import TemplateSalesCTA from '@/components/SalesCTA';
import { Link } from 'react-router-dom';
import Video from '@/assets/Vid.mp4';

const CTAButton = styled(Button2)`
  background-color: #232323;
  color: white;
  padding: 12px 20px;
  border-radius: 0.5rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 0.9rem;

  &:hover {
    background-color: #000000;
    transform: translateY(-2px);
  }

  svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateX(5px);
  }
`;

const BackgroundColor = styled.div`
  background-color: #ffffff;
`;

const ProductContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #9b2626;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30.5%, 3fr));
  gap: 2rem;
  margin-top: 3rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
`;

const ProductCard = styled.div`
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProductInfo = styled.div`
  padding: 1.5rem;
  text-align: center;
`;

const ProductName = styled.h3`
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const ProductServings = styled.p`
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

const ProductPrice = styled.p`
  color: #232323;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

const Products = () => {
  const products = [
    {
      id: 1,
      name: 'Crypto Template Resources Tier 1',
      servings: '20 Resources',
      price: '$40.00',
      URL: 'https://buy.stripe.com/test_14kg2W1iP8N84U09AB',
      imageUrl:
        'https://awesomescreenshot.s3.amazonaws.com/image/6145547/52972137-5c2b0b18ddd3707565e526de3be97a55.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJSCJQ2NM3XLFPVKA%2F20250307%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250307T214105Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&X-Amz-Signature=06d8d4acccda9eee0050e1d8d30669b6bcf7b2941368ee2a4e8adc321cce4b70',
    },
    {
      id: 2,
      name: 'Crypto Template Resources Tier 2',
      servings: '50 Resources',
      price: '$80.00',
      URL: 'https://buy.stripe.com/test_14kg2W1iP8N84U09AB',
      imageUrl:
        'https://imagedelivery.net/C3qORsNB2rkZsApGLyWSGQ/c9d1ad5a-3e6d-4e79-617e-0e5755d5f500/public',
    },
    {
      id: 3,
      name: 'Crypto Template Resources Tier 3',
      servings: '100 Resources',
      price: '$100.00',
      URL: 'https://buy.stripe.com/test_14kg2W1iP8N84U09AB',
      imageUrl:
        'https://fastcdn.ohmilo.xyz/Crypto%20Template%20Tier%201%20Version%202.png',
    },
  ];

  return (
    <BackgroundColor>
      <section className='mx-auto flex max-w-7xl flex-col items-center justify-center gap-16 bg-white px-8 py-16 lg:flex-row lg:gap-20 lg:py-24'>
        <div className='flex flex-col items-center justify-center gap-10 text-center lg:items-start lg:gap-14 lg:text-left'>
          <h1 className='text-4xl font-extrabold tracking-tight md:-mb-4 lg:text-6xl'>
            Crypto Template Resources
          </h1>
          <p className='max-w-2xl text-lg leading-relaxed opacity-80'>
            Discover a curated collection of resources to help you build your
            crypto project. Our templates provide the perfect starting point for
            your next big idea.
          </p>
          <a href='#products' className='btn-primary'>
            <CTAButton className='btn-wide'>
              Get Started
              <ArrowRight />
            </CTAButton>
          </a>
        </div>
        <div className='max-w-lg lg:w-full'>
          <TemplateSalesCTA
            alt='Product Demo'
            className='w-full rounded-lg shadow-lg'
            width={500}
            height={500}
          />
        </div>
      </section>

      <ProductContainer id='products'>
        <h2 className='mb-8 text-center text-3xl font-bold'>
          Choose Your Package
        </h2>
        <ProductGrid>
          {products.map((product) => (
            <ProductCard key={product.id}>
              <ProductImage src={product.imageUrl} alt={product.name} />
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductServings>{product.servings}</ProductServings>
                <ProductPrice>{product.price}</ProductPrice>
                <Link to={`${product.URL}`}>
                  <CTAButton className='btn-wide'>
                    Buy Now
                    <ArrowRight />
                  </CTAButton>
                </Link>
              </ProductInfo>
            </ProductCard>
          ))}
        </ProductGrid>
      </ProductContainer>

      <div className='mx-auto max-w-4xl px-4 py-16'>
        <h2 className='mb-8 text-center text-3xl font-bold'>
          See Our Templates in Action
        </h2>
        <video
          playsInline
          autoPlay
          muted
          width={'100%'}
          height={'auto'}
          className='aspect-square rounded-2xl shadow-lg'
          src={Video}
        />
      </div>
    </BackgroundColor>
  );
};

export default Products;
