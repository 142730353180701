import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const TemplateSalesCTA = () => {
  // Sample data showing template popularity
  const data = [
    { name: 'Oct', sales: 22 },
    { name: 'Nov', sales: 45 },
    { name: 'Dec', sales: 52 },
    { name: 'Jan', sales: 49 },
    { name: 'Feb', sales: 62 },
  ];

  return (
    <div className='mx-auto w-full max-w-md overflow-hidden rounded-lg bg-white shadow-lg'>
      <div className='bg-gradient-to-r from-blue-500 to-purple-600 p-6'>
        <h2 className='mb-2 text-2xl font-bold text-white'>
          Our Templates Are Trending!
        </h2>
        <p className='mb-4 text-white'>
          Join 50+ satisfied customers using our in-depth templates.
        </p>

        <div className='mb-4 h-48 rounded-lg bg-white/10 p-2'>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart data={data}>
              <XAxis dataKey='name' stroke='#ffffff' />
              <YAxis stroke='#ffffff' />
              <Tooltip />
              <Bar dataKey='sales' fill='#ffffff' fillOpacity={0.8} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <p className='mt-3 text-center text-sm text-white/80'>
          Shared file • 100% Secure
        </p>
      </div>
    </div>
  );
};

export default TemplateSalesCTA;
