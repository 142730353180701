// * ==== Icons ====
// TODO: Icons used in the Applicationimport
import {
  HomeIcon,
  CurrencyDollarIcon,
  InformationCircleIcon,
  GlobeAltIcon,
  ShoppingBagIcon,
  BoltIcon,
} from '@heroicons/react/24/solid';
import {
  Home,
  ShoppingCart,
  Users,
  Settings,
  HelpCircle,
  Box,
  Truck,
  HeartHandshake,
  Warehouse,
  BadgePercent,
} from 'lucide-react';

// * ==== Pages ====
// TODO: Main Pages of the Application
export { default as Home } from '@/pages/Home';
export { default as ErrorPage } from '@/pages/404';
export { default as Donate } from '@/pages/Donate';
export { default as ElegantPricingPage } from '@/pages/PricingPage';
export { default as Blog } from '@/pages/Blog';
export { default as Products } from '@/pages/Products';
export { default as PrivacyPolicy } from '@/pages/PrivacyPolicy';
export { default as About } from '@/pages/About';

// * ==== Components ====
// TODO: Main Components of the Application
export { PromoNav } from '@/components/PromoNav';
export { Nav } from '@/components/Nav';
export { Footer } from '@/components/Footer';
export { KofiFloatingChatWidget } from '@/components/Kofi';
export { MusicButton } from '@/components/Howler';

// * ==== Navigation Links====
// TODO: Navigation Links of the Application
interface NAV_LINKS {
  href: string;
  label: string;
  icon?: React.ComponentType; // Optional if some items don't have icons
  tag?: string; // Optional property for tags like "new"
  onClick?: () => void;
}

export const NAV_LINKS = [
  {
    href: '/home',
    label: 'Home',
    icon: HomeIcon,
    children: [
      {
        href: '/products/new',
        label: 'New Arrivals',
        icon: Box,
        tag: 'new',
        description: 'New products just arrived!',
      },
      {
        href: '/products/shipping',
        label: 'Shipping Info',
        icon: Truck,
        descriptinon: 'Shipping information for your order.',
      },
      {
        href: '/products/deals',
        label: 'Special Deals',
        icon: BadgePercent,
        tag: 'beta',
        description: 'Special deals and discounts.',
      },
    ],
  },
  {
    href: '/products',
    label: 'Products',
    icon: HomeIcon,
    tag: 'new',
    children: [
      {
        href: '/products/new',
        label: 'New Arrivals',
        icon: Box,
        tag: 'new',
        description: 'New products just arrived!',
      },
      {
        href: '/products/shipping',
        label: 'Shipping Info',
        icon: Truck,
        descriptinon: 'Shipping information for your order.',
      },
      {
        href: '/products/deals',
        label: 'Special Deals',
        icon: BadgePercent,
        tag: 'beta',
        description: 'Special deals and discounts.',
      },
    ],
  },
  {
    href: 'https://buy.stripe.com/test_14kg2W1iP8N84U09AB',
    label: 'Store',
    icon: ShoppingBagIcon,
    tag: 'new',
  },
  {
    href: '/blog',
    label: 'Blog',
    icon: BoltIcon,
    tag: 'new',
  },
  {
    href: '/about',
    label: 'About',
    icon: InformationCircleIcon,
  },
  // {
  //   href: '/pricing',
  //   label: 'Pricing',
  //   icon: CurrencyDollarIcon,
  // },
  // {
  //   href: '/worlds',
  //   label: 'Worlds',
  //   icon: GlobeAltIcon,
  // },
  {
    href: '/donate',
    label: 'Donate',
    icon: CurrencyDollarIcon,
  },
];