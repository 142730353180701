// seo/metadata.ts
export const routeMetadata = {
  '/': {
    title: 'Home - My SaaS Web App',
    description:
      'Welcome to My SaaS Web App - Your all-in-one solution for business productivity.',
    keywords: 'SaaS, web app, productivity, business software',
    canonical: 'https://mysaasweb.app/',
    ogImage: 'https://mysaasweb.app/og-image.jpg',
  },
  '/about': {
    title: 'About Us - My SaaS Web App',
    description:
      'Learn about the team behind My SaaS Web App and our mission to simplify business processes.',
    keywords: 'about us, SaaS company, team, mission',
    canonical: 'https://mysaasweb.app/about',
    ogImage: 'https://mysaasweb.app/about-og-image.jpg',
  },
  '/blog': {
    title: 'Contact Us - My SaaS Web App',
    description:
      'Get in touch with My SaaS Web App for support, inquiries, or partnerships.',
    keywords: 'contact, support, customer service, SaaS',
    canonical: 'https://mysaasweb.app/contact',
    ogImage: 'https://mysaasweb.app/contact-og-image.jpg',
  },
};
