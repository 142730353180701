import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import 'swiper/swiper-bundle.css';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://8c105966e7dfdaaf90900beea09abbd3@o4507298653863936.ingest.us.sentry.io/4507383306584064',
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.replayIntegration({
      // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
  ],
  tracesSampleRate: 0.5,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
