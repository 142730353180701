import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '/src/assets/react.svg'; // Adjust path as needed
import { NAV_LINKS, PromoNav, icon } from '@/index.ts';
import styled, { keyframes } from 'styled-components';

// Keyframes animation for fadeInUp effect
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

// Styled div component that applies the fadeInUp animation
const AnimatedDiv = styled.div`
  opacity: 0;
  animation: ${fadeInUp} 0.5s ease-out forwards;
  animation-delay: ${(props) => props.index * 0.04}s;
`;

// Styled div component that sets the maximum width for the Navbar content
const NavbarWrapper = styled.div`
  max-width: 105rem;
  margin: auto;
`;

// Functional component for the navigation bar
export const Nav = () => {
  // useLocation hook from react-router-dom to access the current URL location
  const location = useLocation();

  // useState hook to manage the state of the mobile menu (open or closed)
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // useEffect hook to handle side effects related to the mobile menu
  useEffect(() => {
    // If the mobile menu is open, add a class to the body to prevent scrolling
    if (isMenuOpen) {
      document.body.classList.add('overflow-hidden');
    }
    // If the mobile menu is closed, remove the class from the body to allow scrolling
    else {
      document.body.classList.remove('overflow-hidden');
    }

    // Cleanup function to remove the class from the body when the component unmounts
    return () => document.body.classList.remove('overflow-hidden');
  }, [isMenuOpen]); // Only run this effect when the isMenuOpen state changes

  // useEffect hook to close the mobile menu when the route changes
  useEffect(() => {
    setIsMenuOpen(false); // Close the mobile menu
  }, [location]); // Only run this effect when the location changes

  // Return the JSX structure for the navigation bar
  return (
    <header className=''>
      {/* Navigation bar */}
      <nav
        className='fixed top-0 right-0 left-0 z-1000 m-0 mx-auto flex items-center justify-center border-b-1 border-b-slate-200 py-5'
        aria-label='Global'
      >
        {/* Wrapper for the navigation bar content */}
        <NavbarWrapper className='header-wrapper flex items-center justify-between'>
          {/* Link to the homepage with the logo */}
          <div className='flex lg:flex-1'>
            <Link
              to='/'
              className='flex shrink-0 items-center justify-center gap-2'
              title='Homepage'
              onClick={() => window.scrollTo(0, 0)}
            >
              <img
                src={
                  'https://cdn.sanity.io/images/811eq7qv/production/12d4e888ffd9ebb9216cc2b508064a60edc39f2b-1688x893.png'
                }
                alt='logo'
                className='h-5 w-30'
                fetchPriority='high'
                width={32}
                height={52}
              />
            </Link>
          </div>

          {/* Mobile menu button (visible on small screens) */}
          <div className='flex lg:hidden'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5'
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className='sr-only'>Open main menu</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='text-base-content h-6 w-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
                />
              </svg>
            </button>
          </div>

          {/* Navigation links (hidden on small screens) */}
          <ul className='hidden lg:flex lg:items-center lg:justify-center lg:gap-14'>
            {/* Map over the NAV_LINKS array to generate navigation links */}
            {NAV_LINKS.map(({ label, href, tag, children }) => (
              // List item for each navigation link
              <li key={href} className='group relative'>
                {/* Link to the specified href */}
                <Link
                  to={href}
                  className='internal-link flex h-[35px] items-center gap-2 border-b-2 border-transparent hover:border-indigo-500 hover:text-indigo-500'
                  title={label}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {/* Navigation link label */}
                  {label}
                  {/* Conditional rendering for the "new" tag */}
                  {tag == 'new' && (
                    <div className='rounded-md bg-sky-100 px-2 py-1 text-sm font-medium text-sky-500'>
                      {tag} {/* Or some other content */}
                    </div>
                  )}
                  {/* Conditional rendering for the dropdown arrow */}
                  {children && (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='h-4 w-4 transition-transform group-hover:rotate-180'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                      />
                    </svg>
                  )}
                </Link>

                {/* Dropdown for items with children */}
                {children && (
                  <div className='ring-opacity-5 invisible absolute left-0 z-50 mt-3 w-64 rounded-md border-2 border-gray-100 bg-white opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100'>
                    <div className='py-1'>
                      {children.map((child) => (
                        <div
                          key={child.href}
                          className='px-4 py-3 hover:bg-gray-50'
                        >
                          <Link
                            to={child.href}
                            className='flex items-start gap-3'
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            <div className='mt-1 flex-shrink-0 text-indigo-500'>
                              {/* Placeholder for icon */}
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='h-5 w-5'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347c-.75.413-1.667-.13-1.667-.986V5.653z'
                                />
                              </svg>
                            </div>
                            <div>
                              <p className='flex items-center gap-2 text-sm font-medium text-gray-900'>
                                {child.label}
                                {child.tag && (
                                  <span
                                    className={`rounded bg-blue-100 px-1.5 py-0.5 text-xs`}
                                  >
                                    {child.tag}
                                  </span>
                                )}
                              </p>
                              <p className='mt-1 text-xs text-gray-500'>
                                {child.description}
                              </p>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </NavbarWrapper>
      </nav>

      {/* Mobile menu (hidden on large screens) */}
      <div className={`relative ${isMenuOpen ? '' : 'hidden'}`}>
        <div
          className={`navcolor sm:ring-neutral/10 fixed inset-y-0 right-0 z-10000 max-h-screen w-full origin-right transform overflow-y-auto px-4 py-6 transition duration-300 ease-in-out sm:max-w-sm sm:ring-1`}
        >
          {/* Mobile menu header with logo and close button */}
          <div className='flex items-center justify-between'>
            <Link
              to='/'
              className='flex shrink-0 items-center'
              title='Homepage'
            >
              <img
                src={logo}
                alt='logo'
                className='w-8'
                fetchPriority='high'
                width={32}
                height={32}
              />
            </Link>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5'
              onClick={() => setIsMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='h-6 w-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>

          {/* Mobile menu links */}
          <div className='z-500 mt-4 flow-root'>
            <div className='py-2'>
              <div className='flex flex-col items-center justify-center divide-black/10 text-xl'>
                {/* Map over the NAV_LINKS array to generate mobile menu links */}
                {NAV_LINKS.map(({ label, href, icon: Icon, tag }, index) => (
                  // Link to the specified href
                  <Link
                    to={href}
                    key={href}
                    className='link'
                    title={label}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {/* Animated div for each mobile menu link */}
                    <AnimatedDiv
                      index={index}
                      className='color-[#767683] flex h-14 w-80 items-center justify-items-center gap-5 rounded-2xl text-xl tracking-tight hover:bg-slate-100'
                    >
                      {/* Icon for the mobile menu link */}
                      <Icon className='size-6 text-[#5f5f6a]' />
                      {/* Label for the mobile menu link */}
                      {label}
                      {/* Conditional rendering for the "new" tag */}
                      {tag == 'new' && (
                        <div className='rounded-md bg-sky-100 px-2 py-1 text-sm font-medium text-sky-500'>
                          {tag} {/* Or some other content */}
                        </div>
                      )}
                    </AnimatedDiv>
                  </Link>
                ))}
              </div>
            </div>
            <div className='divider'></div>
          </div>
        </div>
      </div>
    </header>
  );
};
