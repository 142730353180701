import { MyLikes } from '@/components/MyLikes';
// import { Overlay } from "./components/Overlay";
import { Projects } from '@/components/Projects';
import { CTA } from '@/components/CTA';
import { CTA2 } from '@/components/CTA2';
import Hero from '@/components/Hero';
// import { Button } from '@/components/ui/button';
// import { Pricing } from '@/components/Pricing';
import { useEffect } from 'react';
// import gsap from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';
// import Flip from 'gsap/Flip';
// import Draggable from 'gsap/Draggable';
import AnimeGirl from '@/assets/Girl.json';
import Mirror from '@/assets/Mirror.json';
import Lottie from 'lottie-react';
import { GsapExample } from '@/components/Gsap';
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import styled, { keyframes } from 'styled-components';
import { Cpu, Zap } from 'lucide-react';
import FAQ from '@/components/FAQ';

// Create a container component for centering
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
`;

const WaveBackground = styled.div`
  width: 50%;
  height: 100px;
  background: linear-gradient(90deg, #f8e7ff, #e3e6ff, #d9f2ff);
  border-radius: 100px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 350px; /* Adjust the width on smaller screens */
  }
`;

const PlaceholderDiv = styled.div`
  min-height: 200px; /* Adjust this value as needed */
  min-width: 300px; /* Adjust this value as needed if width shifting */
  /* Add other styles as needed (e.g., background color) */

  @media (max-width: 768px) {
    width: 350px; /* Adjust the width on smaller screens */
  }
`;

function Home() {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = 'Milos Portfolio';
    return () => {
      document.title = previousTitle;
    };
  }, []);

  const data = [
    { date: '1 Dec', Stripe: 1000, Stripe2: 500, layer3: 200 },
    { date: '8 Dec', Stripe: 1200, Stripe2: 600, layer3: 400 },
    { date: '15 Dec', Stripe: 1500, Stripe2: 800, layer3: 500 },
    { date: '22 Dec', Stripe: 1300, Stripe2: 700, layer3: 300 },
    { date: '29 Dec', Stripe: 1400, Stripe2: 900, layer3: 600 },
    { date: '5 Jan', Stripe: 3000, Stripe2: 1500, layer3: 1000 },
    { date: '12 Jan', Stripe: 10000, Stripe2: 5000, layer3: 3000 },
    { date: '19 Jan', Stripe: 8000, Stripe2: 4000, layer3: 2000 },
    { date: '26 Jan', Stripe: 5000, Stripe2: 2500, layer3: 1500 },
    { date: '2 Feb', Stripe: 3000, Stripe2: 1500, layer3: 800 },
    { date: '9 Feb', Stripe: 2000, Stripe2: 1000, layer3: 600 },
    { date: '16 Feb', Stripe: 1800, Stripe2: 900, layer3: 500 },
  ];

  const buttons = document.querySelectorAll('[aria-controls]');

  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      const expanded = button.getAttribute('aria-expanded') === 'true';
      const controls = button.getAttribute('aria-controls');
      const content = document.getElementById(controls);

      button.setAttribute('aria-expanded', !expanded);

      if (expanded) {
        content.classList.remove('max-h-40');
        content.classList.add('max-h-0');
      } else {
        content.classList.remove('max-h-0');
        content.classList.add('max-h-40');
      }
    });
  });

  return (
    <>
      {/* <Overlay /> */}
      {/* <Editor /> */}
      <Hero />
      <section className='py-16'>
        <div className='mx-auto max-w-5xl bg-white px-6'>
          <h2 className='text-center text-lg font-medium'>
            Your favorite companies are our partners.
          </h2>
          <div className='mx-auto mt-20 flex max-w-4xl flex-wrap items-center justify-center gap-x-12 gap-y-8 sm:gap-x-16 sm:gap-y-12'>
            <img
              className='h-5 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/nvidia.svg'
              alt='Nvidia Logo'
              height='20'
              width='auto'
            />
            <img
              className='h-4 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/column.svg'
              alt='Column Logo'
              height='16'
              width='auto'
            />
            <img
              className='h-4 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/github.svg'
              alt='GitHub Logo'
              height='16'
              width='auto'
            />
            <img
              className='h-5 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/nike.svg'
              alt='Nike Logo'
              height='20'
              width='auto'
            />
            <img
              className='h-4 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/laravel.svg'
              alt='Laravel Logo'
              height='16'
              width='auto'
            />
            <img
              className='h-7 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/lilly.svg'
              alt='Lilly Logo'
              height='28'
              width='auto'
            />
            <img
              className='h-5 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/lemonsqueezy.svg'
              alt='Lemon Squeezy Logo'
              height='20'
              width='auto'
            />
            <img
              className='h-6 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/openai.svg'
              alt='OpenAI Logo'
              height='24'
              width='auto'
            />
            <img
              className='h-4 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/tailwindcss.svg'
              alt='Tailwind CSS Logo'
              height='16'
              width='auto'
            />
            <img
              className='h-5 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/vercel.svg'
              alt='Vercel Logo'
              height='20'
              width='auto'
            />
            <img
              className='h-5 w-fit dark:invert'
              src='https://html.tailus.io/blocks/customers/zapier.svg'
              alt='Zapier Logo'
              height='20'
              width='auto'
            />
          </div>
        </div>
      </section>
      <section className='py-16 md:py-32'>
        <div className='mx-auto max-w-5xl space-y-8 px-6 md:space-y-16'>
          <h2 className='relative z-10 max-w-xl text-4xl font-medium lg:text-5xl'>
            The Lyra ecosystem brings together our models.
          </h2>
          <div className='relative'>
            <div className='relative z-10 space-y-4 md:w-1/2'>
              <p className='text-body'>
                Lyra is evolving to be more than just the models.{' '}
                <span className='text-title font-medium'>
                  It supports an entire ecosystem
                </span>{' '}
                — from products innovate.
              </p>
              <p>
                It supports an entire ecosystem — from products to the APIs and
                platforms helping developers and businesses innovate
              </p>

              <div className='grid grid-cols-2 gap-3 pt-6 sm:gap-4'>
                <div className='space-y-3'>
                  <div className='flex items-center gap-2'>
                    <Zap className='size-4' />
                    <h3 className='text-sm font-medium'>Faaast</h3>
                  </div>
                  <p className='text-muted-foreground text-sm'>
                    It supports an entire helping developers and innovate.
                  </p>
                </div>
                <div className='space-y-2'>
                  <div className='flex items-center gap-2'>
                    <Cpu className='size-4' />
                    <h3 className='text-sm font-medium'>Powerful</h3>
                  </div>
                  <p className='text-muted-foreground text-sm'>
                    It supports an entire helping developers and businesses.
                  </p>
                </div>
              </div>
            </div>
            <div className='mt-12 h-fit md:absolute md:inset-x-0 md:-inset-y-12 md:mt-0'>
              <div
                aria-hidden
                className='to-background absolute inset-0 z-1 hidden bg-linear-to-l from-transparent to-55% md:block'
              ></div>
              <div className='border-border/50 relative rounded-2xl border border-dotted p-2'>
                <img
                  src='https://awesomescreenshot.s3.amazonaws.com/image/6145547/53399591-834751f598f6e40571122a198bc4dc90.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJSCJQ2NM3XLFPVKA%2F20250313%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250313T224715Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&X-Amz-Signature=97344d8e0898c465bc7281a5c1d7de018df7cb17e9aabc24f67dc1e3cdc7899f'
                  className='hidden rounded-[12px] dark:block'
                  alt='payments illustration dark'
                  width={1207}
                  height={929}
                />
                <img
                  src='https://awesomescreenshot.s3.amazonaws.com/image/6145547/53400687-be74fc8d6b7751161e7d56bbc57db59a.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJSCJQ2NM3XLFPVKA%2F20250314%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250314T011904Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&X-Amz-Signature=7356b6ecd93e73d83437ee6c691014a3e58a2365d3a4df6b204e253f21aa883e'
                  className='rounded-[12px] shadow dark:hidden'
                  alt='payments illustration light'
                  width={1207}
                  height={929}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='what-is-mouthwashing'>
        <div class='mx-auto max-w-6xl px-4 sm:px-6'>
          <div class='border-t border-gray-800 py-12 md:pb-20'>
            <div class='mx-auto max-w-3xl pb-12 text-center'>
              <h2 class='h2 mt-6 mb-4 text-black'>What is Mouthwashing?</h2>
            </div>
            <div class='flex flex-col gap-2'>
              <p class='text-xl text-black'>
                Mouthwashing is a narrative-driven first-person horror game set
                aboard the stranded space freighter Tulpar. Players follow the
                eerie and suspenseful journey of the ship’s dying crew, who are
                slowly running out of time and resources. Their captain, Curly,
                has doomed them all to a grim fate after a failed suicide
                attempt, leaving him maimed and helpless. The crew must now
                decide if breaking the company’s strict prohibition on opening
                the mysterious cargo hold could be their only hope for survival.
              </p>
              <p class='text-xl text-black'>
                In this tense and atmospheric setting, players will navigate the
                psychological toll of isolation and the dread of the unknown,
                where every decision carries life-or-death consequences. With a
                chilling space horror vibe, Mouthwashing challenges players to
                face the terrifying realities of survival in the void, all while
                unraveling the dark secrets of the ship’s cargo and the true
                nature of the captain’s betrayal.
              </p>
            </div>
            <div class='mt-8 grid grid-cols-1 gap-4 md:grid-cols-3'>
              <img
                alt='Mouthwashing Gameplay 1'
                loading='lazy'
                width='1919'
                height='1079'
                decoding='async'
                data-nimg='1'
                className='rounded-xl'
                src='https://awesomescreenshot.s3.amazonaws.com/image/6145547/53404343-04970d05f48b3e4c3ba0c18a1db38941.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJSCJQ2NM3XLFPVKA%2F20250314%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250314T075536Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&X-Amz-Signature=036c94c65c38a435efc9eb1bf30c86099fe98664df08fe12b38dcedec4f72901'
              />
              <img
                alt='Mouthwashing Gameplay 2'
                loading='lazy'
                width='1919'
                height='1079'
                decoding='async'
                data-nimg='1'
                className='rounded-xl'
                src='https://awesomescreenshot.s3.amazonaws.com/image/6145547/53400687-be74fc8d6b7751161e7d56bbc57db59a.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJSCJQ2NM3XLFPVKA%2F20250314%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250314T011904Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&X-Amz-Signature=7356b6ecd93e73d83437ee6c691014a3e58a2365d3a4df6b204e253f21aa883e'
              />
              <img
                alt='Mouthwashing Gameplay 3'
                loading='lazy'
                width='1920'
                height='1080'
                decoding='async'
                data-nimg='1'
                className='rounded-xl'
                src='https://awesomescreenshot.s3.amazonaws.com/image/6145547/53400687-be74fc8d6b7751161e7d56bbc57db59a.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJSCJQ2NM3XLFPVKA%2F20250314%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250314T011904Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&X-Amz-Signature=7356b6ecd93e73d83437ee6c691014a3e58a2365d3a4df6b204e253f21aa883e'
              />
            </div>
          </div>
        </div>
      </section>
      <FAQ />
      <div className='flex flex-col items-center justify-center'>
        <ResponsiveContainer
          width='90%'
          height={400}
          className={'mb-10 rounded-lg bg-black'}
        >
          <div className='flex flex-col items-center justify-center rounded-t-lg text-white'>
            Historical Project Performence
          </div>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 50, // Increased bottom margin to accommodate the legend
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date' />
            <YAxis tickFormatter={(value) => `$${value / 1000}K`} />
            <Tooltip
              formatter={(value) => `$${value}`}
              contentStyle={{
                backgroundColor: '#fff',
                border: 'none',
                borderRadius: '15px',
                color: '#222',
              }}
            />
            <Area
              type='monotone'
              dataKey='Stripe'
              stackId='1'
              stroke='#8884d8'
              fill='#8884d8'
            />
            <Area
              type='monotone'
              dataKey='Stripe2'
              stackId='1'
              stroke='#82ca9d'
              fill='#82ca9d'
            />
            <Area
              type='monotone'
              dataKey='layer3'
              stackId='1'
              stroke='#ffc658'
              fill='#ffc658'
            />
            <Area
              type='monotone'
              dataKey='layer4'
              stackId='1'
              stroke='#a4de6c'
              fill='#a4de6c'
            />
            <Area
              type='monotone'
              dataKey='layer5'
              stackId='1'
              stroke='#d0ed57'
              fill='#d0ed57'
            />
            <Area
              type='monotone'
              dataKey='layer6'
              stackId='1'
              stroke='#008080'
              fill='#008080'
            />
            <Area
              type='monotone'
              dataKey='layer7'
              stackId='1'
              stroke='#B0E0E6'
              fill='#B0E0E6'
            />
            <Area
              type='monotone'
              dataKey='other'
              stackId='1'
              stroke='#FF69B4'
              fill='#FF69B4'
              name='Other' // Set the name for the legend
            />
            <Legend
              layout='horizontal'
              verticalAlign='bottom'
              align='center'
              wrapperStyle={{
                paddingTop: 20, // Add some space between the chart and the legend
              }}
              formatter={(value, entry, index) => {
                // Customize legend item rendering
                if (value === 'other') {
                  return (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: '#FF69B4', // Match the area color
                          marginRight: '5px',
                        }}
                      />
                      Other
                    </span>
                  );
                }
                // For other legend items (if you want to customize them)
                return value;
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
        <WaveBackground>
          <section class='bg-gray-50 py-4'>
            <nav class='container mx-auto'>
              <ul class='flex items-center justify-around'>
                <li>
                  <a
                    href='#section1'
                    class='rounded px-4 py-2 transition-colors duration-200 hover:bg-gray-200'
                    data-section='section1'
                  >
                    AI for Support & Sales
                  </a>
                </li>
                <li>
                  <a
                    href='#section2'
                    class='rounded px-4 py-2 transition-colors duration-200 hover:bg-gray-200'
                    data-section='section2'
                  >
                    Built for Shopify
                  </a>
                </li>
                <li>
                  <a
                    href='#section3'
                    class='rounded px-4 py-2 transition-colors duration-200 hover:bg-gray-200'
                    data-section='section3'
                  >
                    One Unified View
                  </a>
                </li>
              </ul>
            </nav>
          </section>
        </WaveBackground>
        <section
          id='section1'
          class='flex min-h-screen items-center justify-center'
        >
          <div>Content for Section 1</div>
        </section>
        <section
          id='section2'
          class='flex min-h-screen items-center justify-center'
        >
          <div>Content for Section 2</div>
        </section>
        <section
          id='section3'
          class='flex min-h-screen items-center justify-center'
        >
          <div>Content for Section 3</div>
        </section>
        <h1 className='text-2xl'>
          <span className='text-red-500'>★Welcome to my website!</span>
        </h1>
        <MyLikes />
        <Projects className='w-[100px]' />
        {/* <GsapExample /> */}
        {/* <div className='pointer-events-none relative flex h-full w-full items-center justify-center overflow-hidden'>
        <img
          src='https://act-webstatic.hoyoverse.com/puzzle/hkrpg/pz_3z780LOFem/resource/puzzle/2024/03/29/a226a2b42bd6035d07ccfbbe7432f98e_4515112973596349785.png?x-oss-process=image/format,webp/quality,Q_90'
          className='absolute; width: 19.2rem; height: 11.02rem; left: 0rem; top: 0rem; font-size: 72.9167px; pointer-events-none z-10 h-full w-full cursor-crosshair'
        ></img>
        <img
          src='https://pbs.twimg.com/media/GOxR2cHXgAAs6Yh?format=jpg&name=4096x4096'
          className='absolute left-0 z-0 h-full w-full object-cover'
        ></img>
        <img
          src='https://fastcdn.hoyoverse.com/mi18n/hkrpg_global/m20240619hy3959a7sw/upload/1fb05c0e175662facd48fe814de754c7_3638935155827358988.png?x-oss-process=image/format,webp/quality,Q_90'
          className='width: 8.9rem; height: 1.8rem; right: 0.24rem; top: 9.14rem; font-size: 72.9167px; z-5 absolute'
        ></img>
      </div> */}
        <div>Currently in production</div>
        <div>I make static and dynamic websites.</div>
        {/* <a href='https://buy.stripe.com/14k6rrben6xNeD63cd' target='_blank'>
          <img
            height='36'
            width={'500'}
            className='kofi-button'
            src='https://cdn.prod.website-files.com/5c14e387dab576fe667689cf/64f1a9ddd0246590df69ea01_kofi_long_button_blue%25402x-p-500.png'
            alt='Buy Me a Coffee at ko-fi.com'
          />
        </a> */}
        {/* <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='708'
            src='https://www.youtube.com/embed/a2k2buMMjJw?list=RDxh1MKfA12cY'
            title='【MAD】銀魂 あなたの夜が明けるまで×坂田銀時'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='720'
            src='https://www.youtube.com/embed/trX9TiYH-Kk?'
            title='【銀魂】完結?!!!!! | Gintama Full Story?! | [MAD] | SPYAIR "I Wanna Be..."'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='720'
            src='https://www.youtube.com/embed/MExpJUwGtTI?list=RDxh1MKfA12cY'
            title='〖 MAD 〗銀魂 × I Wanna Be…'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='720'
            src='https://www.youtube.com/embed/POmXPjHsdD4?list=RDxh1MKfA12cY'
            title='【MAD】銀魂 THE FINAL × サクラミツツキ'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='1280'
            height='720'
            src='https://www.youtube.com/embed/LtDIJJFwOi8?list=RDxh1MKfA12cY'
            title='【MAD】銀魂×灰色と青【〜吉田松陽の弟子たち〜】'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section className='video-container'>
        <div className='flex bg-white'>
          <iframe
            className='w-full rounded-lg'
            width='983'
            height='737'
            src='https://www.youtube.com/embed/AuqxF-AA3ik?list=LL'
            title='Yoga vs camus'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerpolicy='strict-origin-when-cross-origin'
            allowfullscreen
          ></iframe>
        </div>
      </section> */}
        {/* <div className='flex items-center justify-center'>
          <Lottie animationData={AnimeGirl} loop={true} />
        </div> */}
        <CTA />
        <CTA2 />
        <div class='flex w-full flex-col items-center gap-2 bg-[#8aa9ff] p-4'>
          <a href='https://ko-fi.com/P5P610HV8F' target='_blank'>
            <img
              height='36'
              width={'300'}
              className='kofi-button'
              src='https://cdn.prod.website-files.com/5c14e387dab576fe667689cf/64f1a9ddd0246590df69ea01_kofi_long_button_blue%25402x-p-500.png'
              alt='Buy Me a Coffee at ko-fi.com'
            />
          </a>
          <button class='flex items-center gap-2 rounded-full bg-sky-500 px-4 py-3 font-medium text-white transition duration-200 hover:bg-sky-700'>
            <img
              src='https://storage.ko-fi.com/cdn/cup-border.png'
              alt='Buy Me a Coffee'
              class='h-6 w-6'
            />
            I work hard to offer this for free
          </button>
          <button class='flex items-center gap-2 rounded-full bg-purple-500 px-4 py-3 font-medium text-white transition duration-200 hover:bg-purple-700'>
            <img
              src='https://storage.ko-fi.com/cdn/cup-border.png'
              alt='Buy Me a Coffee'
              class='h-6 w-6'
            />
            Your donation would mean a lot!
          </button>
          <button class='flex items-center gap-2 rounded-full bg-rose-500 px-4 py-3 font-medium text-white transition duration-200 hover:bg-rose-700'>
            <img
              src='https://storage.ko-fi.com/cdn/cup-border.png'
              alt='Buy Me a Coffee'
              class='h-6 w-6'
            />
            Buy Me Coffee <span class='ml-1'>❤️</span>
          </button>
          <button class='flex items-center gap-2 rounded-full bg-red-400 px-4 py-3 font-medium text-white transition duration-200 hover:bg-red-600'>
            <img
              src='https://storage.ko-fi.com/cdn/cup-border.png'
              alt='Buy Me a Coffee'
              class='h-6 w-6'
            />
            Donate Now <span class='ml-1'>❤️</span>
          </button>
        </div>
      </div>
    </>
  );
}
export default Home;
