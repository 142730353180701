import { useState } from 'react';

const FAQ = () => {
  const faqData = [
    {
      question: 'What is Mouthwashing about?',
      answer:
        'Mouthwashing is a narrative-driven horror game set on a shipwrecked space freighter, where the crew faces a slow descent into madness.',
    },
    {
      question: 'How long does it take to complete Mouthwashing?',
      answer:
        'The game takes approximately three hours to complete, with a tightly focused narrative experience.',
    },
    {
      question: 'What is the gameplay like in Mouthwashing?',
      answer:
        'Mouthwashing focuses on narrative exploration, with minimal combat or survival mechanics, emphasizing storytelling and atmosphere.',
    },
    {
      question: 'Is there any replayability in Mouthwashing?',
      answer:
        'While Mouthwashing has a linear narrative, its surreal visuals and psychological depth may encourage players to revisit scenes.',
    },
    {
      question: 'Can I play Mouthwashing on Steam?',
      answer: 'Yes, Mouthwashing is available for purchase on Steam.',
    },
    {
      question: 'What kind of horror can I expect in Mouthwashing?',
      answer:
        'The game features psychological horror, surreal visuals, and unsettling atmosphere rather than jump scares.',
    },
    {
      question: 'What is the story of the crew in Mouthwashing?',
      answer:
        'The crew of the Tulpar spaceship struggles to survive after a catastrophic crash, with diminishing supplies and growing psychological tension.',
    },
    {
      question: 'What are the system requirements for Mouthwashing?',
      answer:
        'Minimum requirements include a 64-bit processor, Windows 7, Intel Core i5-4400E, 4GB RAM, and NVIDIA GeForce GTX 560 or AMD Radeon HD 6870.',
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFAQ = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section className='container mx-auto mb-20 max-w-4xl p-5' id='faq'>
      <div className='py-12 md:py-20'>
        <div className='mb-6 flex flex-col items-center md:mb-12 lg:mb-16'>
          <h2 className='font-inter-tight h2 mb-4 text-center text-white'>
            Mouthwashing: Frequently Asked Questions
          </h2>
          <p className='text-center text-xl text-gray-400'>
            Everything you need to know about gameplay, story, and technical
            details of Mouthwashing, the unsettling space horror game.
          </p>
        </div>
        <div className='mx-auto max-w-3xl' id='faq'>
          <div className='space-y-2'>
            {faqData.map((item, index) => (
              <div key={index} className='rounded bg-white'>
                <h3>
                  <button
                    className='font-inter-tight flex w-full items-center justify-between px-4 py-2.5 text-left font-medium text-zinc-800'
                    aria-expanded={expandedIndex === index}
                    aria-controls={`accordion-text-faqs-${index}`}
                    onClick={() => toggleFAQ(index)}
                  >
                    <span>{item.question}</span>
                    <svg
                      className='ml-8 shrink-0 fill-zinc-400 transition-transform'
                      width='12'
                      height='12'
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M5 5V0H7V5H12V7H7V12H5V7H0V5H5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </button>
                </h3>
                <div
                  id={`accordion-text-faqs-${index}`}
                  role='region'
                  aria-labelledby={`accordion-title-faqs-${index}`}
                  className={`transition-max-height overflow-hidden text-sm text-zinc-500 duration-300 ${
                    expandedIndex === index ? 'max-h-40' : 'max-h-0'
                  }`}
                >
                  <div className='px-4 pb-3'>
                    <p>{item.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
