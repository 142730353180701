import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px;
`;

const Title = styled.h1`
  font-size: 48px;
  /* font-size: 3.75rem; */
  font-weight: 600;
  margin-bottom: 20px;
  max-width: 800px;
  line-height: 1.2;
  letter-spacing: -1px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
  max-width: 500px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 50px;
`;

const Button2 = styled(Button)`
 height: 50px;
`;

const WaveBackground = styled.div`
  width: 50%;
  height: 100px;
  background: linear-gradient(90deg, #f8e7ff, #e3e6ff, #d9f2ff);
  border-radius: 100px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, #f0d1ff 0%, #c4c8ff 50%, #c9e9ff 100%);
    border-radius: 100px;
    opacity: 0.7;
  }
`;

const Hero: React.FC = () => {
  return (
    <HeroSection>
      <Title>The Future of Customer Service is AI</Title>
      <Subtitle>
        With  Kai (AI bot),  visual bug reporting,  live chat,  public
        roadmaps, and so much more, we help you improve your product, boost
        sales and strengthen customer relationships.
      </Subtitle>
      <ButtonGroup>
        <Button2 primary>Sign Up for Free</Button2>
        <Button2 outlined>Chat with us</Button2>
      </ButtonGroup>
      {/* <WaveBackground /> */}
    </HeroSection>
  );
};

export default Hero;
