import { useEffect, useState } from 'react';
import { client } from '../lib/sanity';
import { BookOpen, AlertCircle } from 'lucide-react';
import type { PostTitle } from '../types';
import { PortableText } from '@portabletext/react';
import { CTA } from '@/components/CTA';
import { Link } from 'react-router-dom';

// Helper components for each pageBuilder type
const Hero = ({ content }) => (
  <div className='hero'>
    <h2>{content?.heading}</h2>
    <p>{content?.tagline}</p>
    {content?.backgroundImage && (
      <img src={content.backgroundImage.asset.url} alt='Hero background' />
    )}
  </div>
);

const TextSection = ({ content }) => (
  <div className='text-section'>
    <h3>{content?.heading}</h3>
    <div className='text-section-content'>
      <PortableText value={content?.body} />
    </div>
  </div>
);

const ImageGallery = ({ content }) => (
  <div className='image-gallery'>
    {content?.images?.map((image, index) => (
      <img
        key={index}
        src={image.asset.url}
        alt={`Gallery image ${index + 1}`}
      />
    ))}
  </div>
);

const CallToAction = ({ content }) => (
  <div className='call-to-action'>
    <h3>{content?.heading}</h3>
    <a href={content?.url}>{content?.buttonText}</a>
  </div>
);

const Form = ({ content }) => (
  <div className='form'>
    <h3>{content?.name}</h3>
    {/* Render form fields here */}
  </div>
);

export const BlogPage = () => {
  const [posts, setPosts] = useState<PostTitle[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      const query = `
        *[_type == "Post" && slug.current == '${window.location.pathname.split('/').pop()}'] {
          _id,
          name,
          date,
          "categories": categories[]->title,
          body,
          image {
            asset-> {
              _id,
              url
            }
          },
          authors,
          pageBuilder[] {
            ...,
            _type == 'hero' => {
              ...,
              backgroundImage {
                asset-> {
                  url
                }
              }
            },
            _type == 'imageGallery' => {
              ...,
              images[] {
                asset-> {
                  url
                }
              }
            }
          },
          caseStudyLabel,
          conclusion
        }
      `;

      try {
        const fetchedPosts = await client.fetch(query);
        setPosts(fetchedPosts);
        setError(null);
      } catch (err) {
        console.error('Error fetching posts:', err);
        setError('Failed to load blog posts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const [latestPosts, setLatestPosts] = useState<PostTitle[]>([]);

  useEffect(() => {
    const fetchLatestPosts = async () => {
      const latestPostsQuery = `
        *[_type == "Post" && slug.current != '${window.location.pathname.split('/').pop()}'] | order(date)  {
          _id,
          name,
          date,
          slug,
          "categories": categories[]->title,
          image {
            asset-> {
              _id,
              url
            }
          },
          authors,
          pageBuilder[] {
            ...,
            _type == 'hero' => {
              ...,
              backgroundImage {
                asset-> {
                  url
                }
              }
            },
          },
        }
      `;

      try {
        const fetchedLatestPosts = await client.fetch(latestPostsQuery);
        setLatestPosts(fetchedLatestPosts);
      } catch (err) {
        console.error('Error fetching latest posts:', err);
      }
    };

    fetchLatestPosts();
  }, []);

  if (loading) {
    return (
      <div className='flex min-h-screen items-center justify-center bg-white'></div>
    );
  }

  return (
    <div className='bg-white'>
      {/* Post Back Link */}
      <a
        href='/blog'
        className='mt-4 mb-2 ml-3 inline-block rounded-2xl bg-white p-2 px-10 text-sm font-medium text-gray-500 outline-1 hover:text-gray-700 lg:mt-15 lg:ml-35 lg:px-15'
      >
        - BACK
      </a>
      {/* Main Content Wrapper */}
      <div className='max-w-8xl mx-auto flex justify-center bg-white'>
        {/* Blog Content */}
        <div className=' bg-white sm:px-5 lg:pr-5'>
          <div className='space-y-4'>
            {posts.map((post) => (
              <article
                key={post._id}
                className='max-w-3xl bg-white p-5 lg:p-0'
              >
                {/* Post Blog Tag */}
                <div className='mb-2 flex items-center gap-2 pt-5 text-xs'>
                  BLOG |
                  {post.categories?.map((category) => (
                    <span
                      key={category}
                      className='rounded-full bg-indigo-50 px-3 py-1 text-xs font-medium text-indigo-700'
                    >
                      {category}
                    </span>
                  ))}
                </div>

                {/* Post Title */}
                <h1 className='mb-2 text-3xl font-bold'>{post.name}</h1>
                {/* Post Author and Date */}
                <div className='mb-4 flex text-gray-600'>
                  <p className='text-sm'>{post.date}</p>
                </div>
                {/* ALF Case Study Label */}
                <div className='mb-4 flex flex-wrap gap-2'>
                  {post.categories?.map((category) => (
                    <span
                      key={category}
                      className='rounded-full bg-indigo-50 px-3 py-1 text-xs font-medium text-indigo-700'
                    >
                      {category}
                    </span>
                  ))}
                </div>
                {/* Post Line */}
                <hr className='my-8 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100' />
                {/* Post Image */}
                {post.image?.asset?.url && (
                  <img
                    src={post.image.asset.url}
                    alt={post.name}
                    className='mb-10 rounded-md object-cover lg:aspect-759/587'
                  />
                )}
                {/* Post Body */}
                <div className='mx-auto w-full'>
                  <PortableText
                    value={post.body}
                    components={{
                      block: {
                        h1: ({ children }) => (
                          <h1 className='text-2xl font-bold'>{children}</h1>
                        ),
                        h2: ({ children }) => (
                          <h2 className='text-2xl font-bold'>{children}</h2>
                        ),
                        h3: ({ children }) => (
                          <h3 className='text-2xl font-medium'>{children}</h3>
                        ),
                        h4: ({ children }) => (
                          <h4 className='text-xl font-bold'>{children}</h4>
                        ),
                        h5: ({ children }) => (
                          <h5 className='text-lg font-bold'>{children}</h5>
                        ),
                        blockquote: ({ children }) => (
                          <blockquote className='mt-2 mb-2 border-l-4 border-gray-300 pl-4 italic'>
                            {children}
                          </blockquote>
                        ),
                        normal: ({ children }) => (
                          <p className='mb-[24px] text-[17px] text-[#262626]'>
                            {children}
                          </p>
                        ),
                      },
                    }}
                  />
                </div>
                <div className='mt-10 flex flex-wrap gap-2'>
                  {post.categories?.map((category) => (
                    <span
                      key={category}
                      className='rounded-full bg-gray-100 px-3 py-1 text-xs font-medium text-gray-700'
                    >
                      {category}
                    </span>
                  ))}
                </div>
                {post.pageBuilder &&
                  post.pageBuilder.map((content, index) => {
                    switch (content._type) {
                      case 'hero':
                        return <Hero key={index} content={content} />;
                      case 'imageGallery':
                        return <ImageGallery key={index} content={content} />;
                      case 'callToAction':
                        return <CallToAction key={index} content={content} />;
                      case 'form':
                        return <Form key={index} content={content} />;
                      default:
                        return null;
                    }
                  })}
                {/* Post Line */}
                <hr className='my-4 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100' />
                <div className='mt-10 mb-10 flex-col items-center justify-center'>
                  <CTA />
                </div>
                {/* Post Line */}
                <hr className='my-4 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100' />
                <div className='mx-auto py-8'>
                  <h2 className='text-semibold mb-2 text-lg'>About WebFast</h2>
                  <p className='text-sm text-gray-700'>
                    Favorite phrase/slogan: "I don't know everything, I just
                    know what I know."
                    (何でもは知らないわよ。知ってることだけ。, Nandemo wa
                    shiranaiwa yo. Shitteru koto dake.)
                  </p>
                </div>
              </article>
            ))}
          </div>
        </div>

        {/* Latest Articles Section */}
        <aside className='hidden lg:block'>
          <div className='space-y-6 border-b border-gray-300 pb-4'>
            <h2 className='text-lg font-semibold'>Latest Articles</h2>
            <div className='flex flex-col'>
              {latestPosts.slice(0, 3).map((post) => (
                <div key={post._id} className='mb-4 flex'>
                  {post.image?.asset?.url && (
                    <img
                      src={post.image.asset.url}
                      alt={post.name}
                      className='mr-2 h-[96px] w-[179px] rounded-md object-cover' // Adjusted width and height
                    />
                  )}
                  <div className='flex w-55 flex-col justify-center'>
                    <h3 className='font-semibold'>{post.name}</h3>
                    <p className='text-sm text-gray-500'>{post.date}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};
