import React from 'react';
import { useEffect, useState } from 'react';
import { client } from '../lib/sanity';
import { AlertCircle } from 'lucide-react';
import type { PostTitle } from '../types';
import { Link } from 'react-router-dom';

const BlogPage = () => {
  const [posts, setPosts] = useState<PostTitle[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      const query = `
        *[_type == "Post"] {
          _id,
          name,
          slug,
          date,
          image {
            asset-> {
              _id,
              url
            }
          }
        }
      `;

      try {
        const fetchedPosts = await client.fetch(query);
        setPosts(fetchedPosts);
        setError(null);
      } catch (err) {
        console.error('Error fetching posts:', err);
        setError('Failed to load blog posts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

    if (loading) {
      return (
        <div className='flex min-h-screen items-center justify-center bg-white'></div>
      );
    }

  return (
    <div className='bg-white font-sans text-gray-800'>
      {/* Main Content */}
      <main className='container mx-auto max-w-7xl px-4 py-8'>
        <h1 className='mb-8 text-3xl font-bold'>Blog</h1>

        <div className='flex flex-col gap-8 lg:flex-row'>
          {/* Sidebar */}
          <aside className='hidden lg:block lg:w-1/4'>
            <nav>
              <ul className='space-y-2'>
                <li>
                  <a
                    href='#'
                    className='block rounded-md bg-indigo-100 px-4 py-2 font-medium text-indigo-700'
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href='#'
                    className='block rounded-md px-4 py-2 hover:bg-gray-100'
                  >
                    Customer Cases
                  </a>
                </li>
                <li>
                  <a
                    href='#'
                    className='block rounded-md px-4 py-2 hover:bg-gray-100'
                  >
                    Business Insights
                  </a>
                </li>
                <li>
                  <a
                    href='#'
                    className='block rounded-md px-4 py-2 hover:bg-gray-100'
                  >
                    Quick Tips
                  </a>
                </li>
              </ul>
            </nav>
            <div className='mt-8'>
              <input
                type='text'
                placeholder='Search'
                className='w-full rounded-full border px-4 py-2 focus:ring-2 focus:ring-indigo-200 focus:outline-none'
              />
            </div>
          </aside>

          {/* Content Area */}
          <div className='lg:w-3/4'>
            <section className='mb-8'>
              <h2 className='mb-4 text-xl font-semibold'>Recent Articles</h2>
              <div className='flex flex-col items-center sm:flex-row'>
                <div className='mb-4 sm:w-1/2 sm:pr-4'>
                  <img
                    src='https://static1.cbrimages.com/wordpress/wp-content/uploads/2024/10/rezero-season-3-poster.jpg'
                    alt='Integrating Retail and Online Customer Data'
                    className='rounded-md'
                    width={622}
                    height={624}
                    style={{ objectFit: 'cover' }}
                  />
                </div>
                <div className='mt-4 sm:mt-0 sm:w-1/2 sm:pl-4'>
                  <h3 className='mb-2 text-2xl font-semibold'>
                    Integrating Retail and Online Customer Data
                  </h3>
                  <p className='mb-2 text-sm text-gray-600'>
                    Dec 20, 2024 · Anna
                  </p>
                  <p className='text-gray-700'>
                    Omnichannel: a term used in e-commerce and retail to
                    describe a business strategy that al...
                  </p>
                </div>
              </div>
            </section>

            {/* Additional Articles */}
            <section className='mx-auto max-w-7xl py-12'>
              <h2 className='mb-6 text-xl font-bold text-gray-900'>
                Blog Posts
              </h2>
              <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                {posts.map((post) => (
                  <Link
                    key={post._id}
                    to={`/blog/${post.slug?.current}`}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                      });
                    }}
                  >
                    <div className='overflow-hidden rounded-lg bg-white shadow-md'>
                      {post.image?.asset?.url && (
                        <img
                          src={post.image.asset.url}
                          alt={post.name}
                          className='h-48 w-full object-cover'
                        />
                      )}
                      <div className='p-4'>
                        <h3 className='mb-2 overflow-hidden text-lg font-semibold text-ellipsis whitespace-nowrap text-gray-900'>
                          {post.name}
                        </h3>
                        {post.date && (
                          <p className='text-sm text-gray-600'>{post.date}</p>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BlogPage;
